// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

   --ion-font-family:'Poppins';

	--ion-color-primary: #69c6ae;
	--ion-color-primary-rgb: 105,198,174;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #5cae99;
	--ion-color-primary-tint: #78ccb6;

  --ion-color-secondary: #2e3436;
  --ion-color-secondary-rgb: 46,52,54;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #282e30;
  --ion-color-secondary-tint: #43484a;

  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 255,255,255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #e0e0e0;
  --ion-color-tertiary-tint: #ffffff;
  
  --ion-color-quaternary: #474c8d;
	--ion-color-quaternary-rgb: 71,76,141;
	--ion-color-quaternary-contrast: #ffffff;
	--ion-color-quaternary-contrast-rgb: 255,255,255;
	--ion-color-quaternary-shade: #3e437c;
	--ion-color-quaternary-tint: #595e98;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
  --ion-color-primary: #69c6ae;
	--ion-color-primary-rgb: 105,198,174;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #5cae99;
	--ion-color-primary-tint: #78ccb6;

  --ion-color-secondary: #2e3436;
  --ion-color-secondary-rgb: 46,52,54;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #282e30;
  --ion-color-secondary-tint: #43484a;

  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 255,255,255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #e0e0e0;
  --ion-color-tertiary-tint: #ffffff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146,148,156;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,245,248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {


  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
   
  }
  
  .ion-color-quaternary {
  --ion-color-base: var(--ion-color-quaternary);
  --ion-color-base-rgb: var(--ion-color-quaternary-rgb);
  --ion-color-contrast: var(--ion-color-quaternary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quaternary-contrast-rgb);
  --ion-color-shade: var(--ion-color-quaternary-shade);
  --ion-color-tint: var(--ion-color-quaternary-tint);
}
}
