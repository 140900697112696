/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~css-star-rating/css/star-rating.css';

.rating.color-ok .star-container .star {
  svg {
    fill:  #FFC300  !important;
  }
  i {
    color:  #FFC300;
  }
}

.rating.color-positive .star-container .star {
  svg {
    fill:  #FFC300 !important;
  }
  i {
    color:  #FFC300;
  }
}
.rating.color-negative .star-container .star {
  svg {
    fill:  #FFC300 !important;
  }
  i {
    color:  #FFC300;  
  }
}



@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap');

.rating{
	justify-content: left !important;
	margin-bottom:0 !important;
	margin-left:-8px;
}

@media screen and (max-width: 800px) and (min-width: 300px) {

.hide-mobile{
	display:none;
	visibility: hidden;
}
}

@media screen and (min-width: 900px) {

.hide-desktop{
	display:none;
	visibility: hidden;
}
}
.clickable{
	cursor:pointer;
}
.border-bottom{
	border-bottom:1px solid #ccc;
}
.spacer-30{
	min-height:30px;
}
.display-flex{
	display:flex !important;
}
.padding-0{
	padding:0 !important;
}
.height-100{
	height:100% !important;
}
.text-center{
	text-align: center !important;
}
.light-green-button{
  background:#D5F2EB;
  --background:#D5F2EB;
  border-radius:10px;
  color: var(--ion-color-secondary);
}